globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"2ff22f75d213b90c8fa9ee10f9e0fd171405bffe"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/browser";
Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: "https://3442d900fa1ce857bdff7af4ac07fba0@o1264099.ingest.sentry.io/4505719874256896",
  enableTracing: process.env.NEXT_PUBLIC_SENTRY_LOGGING_ENABLED === "true",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration()],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE ? +process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE : 0.25,
});
